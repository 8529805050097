
<template>
  <!-- 会员权益体系 -->
  <div>
    <div class="heading">
      <h3>长租式公寓行业解决方案</h3>
      <p>
        长租式公寓行业在市场的驱动下，搭建以数字化驱动的完整营销体系，开启未来公寓新生态的数字化时代。
      </p>
      <button @click="onapply()">申请试用</button>
    </div>
    <div class="problem">
      <h6>长租式公寓行业面临的普遍问题</h6>
      <div>
        <p><span></span>找房难、获客难</p>
        <p><span></span>租金贷</p>
        <p><span></span>客户租房体验差</p>
        <p><span></span>线上营销活动问题</p>
      </div>
    </div>
    <div class="digitization">
      <h5>微心动助力长租式公寓行业实现数字化转型</h5>
      <div class="digitization_c">
        <div class="digitization_t">
          <img src="../../assets/images/number01.png" />
          <div>
            <h6>1、数字化运营方案，功能强大，更贴近公寓运营场景</h6>
            <p>
              针对不同规模的公寓提供高效的数字化决绝方案，房源、人员、财务等管理功能紧贴实际场景
            </p>
          </div>
        </div>
        <div class="digitization_t">
          <img src="../../assets/images/number05.png" />
          <div>
            <h6>2、数据可留存、可导出，助力企业决策</h6>
            <p>
              数据分析直接了解最真实的业务状况，直观反馈业务发展动态，辅助企业决策，助力企业发展
            </p>
          </div>
        </div>
        <div class="digitization_t">
          <img src="../../assets/images/number06.png" />
          <div>
            <h6>3、产品形态多样化，移动办公，管理更高效</h6>
            <p>覆盖小程序、公众号、pc客户端，自由办公，管理更高效</p>
          </div>
        </div>
        <div class="digitization_t">
          <img src="../../assets/images/number07.png" />
          <div>
            <h6>4、线上线下活动管理，租户体验佳</h6>
            <p>
              通过线上选房、缴费、报修等服务活动，全面掌握活动每个阶段的数据信息，提升管理效率，简化活动运营流程
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="modular">
      <h6>三大基础模块，十八大功能，让企业管理无懈可击</h6>
      <div class="modular_c">
        <img src="../../assets/images/douaho_h.png" />
        <img class="Basics" src="../../assets/images/Basics.png" >
        <!-- <div class="modular_m">
          <div class="modular_x">
            <h5>房源管理</h5>
            <div>
              <p v-for="(v, index) in resources" :key="index">
                <span></span>{{ v }}
              </p>
            </div>
          </div>
          <div class="modular_x">
            <h5>财务管理</h5>
            <div>
              <p v-for="(v, index) in finance" :key="index">
                <span></span>{{ v }}
              </p>
            </div>
          </div>
          <div class="modular_x">
            <h5>售后管理</h5>
            <div>
              <p v-for="(v, index) in aftermarket" :key="index">
                <span></span>{{ v }}
              </p>
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <div class="highlights">
      <h6>四大亮点功能 轻松解决公寓管理痛点</h6>
      <div class="highlights_c">
        <div v-for="(v, index) in highlights" :key="index">
          <h4>
            <span>{{ index + 1 }}</span>
            {{ v.title }}
          </h4>
          <p>
            {{ v.content }}
          </p>
        </div>
      </div>
    </div>
    <div class="industry">
      <h6>已服务长租式公寓行业100＋</h6>
      <button @click="onapply()">申请试用</button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["childEvent"],
  data() {
    return {
      resources: [
        "整租管理",
        "合租管理",
        "集中整栋管理",
        "储备房源",
        "储备客源",
        "电子签约",
        "电子交割单",
        "在线付租",
        "一键发房",
        "代金券",
      ],
      finance: [
        "收租提醒",
        "自动催租",
        "流水账管理",
        "电子票据",
        "流水审批",
        "负债统计",
        "租差统计",
        "空置率统计",
        "自动生成收租报表",
      ],
      aftermarket: [
        "维修管理",
        "保洁管理",
        "微信报修",
        "微信投诉",
        "预约退房",
        "预约转租",
      ],
      highlights: [
        {
          title: "账单自动提醒",
          content: "应收租金自动提醒公寓老板和租客",
        },
        {
          title: "微信在线收租",
          content: "租客通过公众号在线支付房租到公司账户，更加安全和便捷",
        },
        {
          title: "微信在线报修",
          content:
            " 租客通过公众号全程自助报修、上传图片、查看进度、服务点评等",
        },
        {
          title: "电子签约",
          content: "无需纸质合同，直接手机操作，成本低，速度快",
        },
      ],
    };
  },
  created() {},
  mounted() {},
  methods: {
    onapply() {
      // this.childEvent(true);
      window.location.href = "http://xdnmxba7z7d6llfo.mikecrm.com/uMx7QGf";
    },
  },
};
</script>

<style scoped lang="less">
.heading {
  height: 6.6rem;
  background-image: url(../../assets/images/industry02.png);
  background-size: 100% 100%;
  text-align: center;
  color: #fff;
  h3 {
    font-size: 0.56rem;
    padding-top: 2.05rem;
  }
  p {
    width: 7.43rem;
    margin: 1em auto 0;
    font-size: 0.2rem;
    line-height: 0.3rem;
    text-align: left;
  }
  button {
    width: 1.48rem;
    height: 0.64rem;
    background-color: #ffc53d;
    border: 0;
    color: #fff;
    font-size: 0.2rem;
    margin-top: 0.4rem;
    border-radius: 0.05rem;
  }
}
.problem {
  padding: 1.36rem 3.26rem 1rem;
  h6 {
    font-size: 0.28rem;
    text-align: center;
  }
  div {
    padding-top: 0.58rem;
    display: flex;
    justify-content: space-between;
    p {
      font-size: 0.22rem;
      font-weight: 600;
      width: 3.02rem;
      background-color: #fffff5;
      line-height: 1.18rem;
      text-align: center;
      span {
        width: 0.16rem;
        height: 0.16rem;
        border-radius: 50%;
        background-color: #ffc53d;
        display: inline-block;
        margin-right: 0.09rem;
      }
    }
  }
}
.digitization {
  background-color: #fffff5;
  padding: 0 1.75rem;
  h5 {
    font-size: 0.28rem;
    padding: 0.7rem 0 0.58rem;
    text-align: center;
  }
  .digitization_c {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .digitization_t {
      width: 7.72rem;
      display: flex;
      box-sizing: border-box;
      padding: 0.36rem 0.64rem 0.36rem 0.36rem;
      background-color: #fff;
      margin-bottom: 0.42rem;
      border-radius: 0.08rem;
      img {
        width: 1rem;
        height: 1rem;
      }
      div {
        padding-left: 0.36rem;
        h6 {
          font-size: 0.22rem;
          line-height: 0.3rem;
          padding-bottom: 0.1rem;
        }
        p {
          font-size: 0.18rem;
          line-height: 0.27rem;
          color: #666;
        }
      }
    }
  }
}
.modular {
  padding: 1rem 2.2rem;
  h6 {
    font-size: 0.28rem;
    color: #333;
    text-align: center;
    padding-bottom: 0.58rem;
  }
  .modular_c {
    display: flex;
    justify-content: center;
    img {
      width: 0.88rem;
      height: 2.07rem;
      margin-right: 0.38rem;
    }
    .Basics{
      width: 12.54rem;
      height:2.48rem ;
    }
    // .modular_m {
    //   display: flex;
    //   justify-content: space-between;
    //   width: 12.54rem;
    //   height: 2.48rem;
    //   box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
    // }
    // .modular_x {
    //   width: 4.5rem;
    //   box-sizing: border-box;
    //   padding: 0.42rem 0.2rem;
    //   h5 {
    //     font-size: 0.22rem;
    //     padding-bottom: 0.16rem;
    //   }
    //   div {
    //     display: flex;
    //     flex-wrap: wrap;
    //     justify-content: space-between;
    //     p {
    //       width: 33%;
    //       span {
    //         display: inline-block;
    //         width: 0.08rem;
    //         height: 0.08rem;
    //         border-radius: 50%;
    //         background-color: #ffc53d;
    //         margin-right: 0.05rem;
    //       }
    //       font-size: 0.18rem;
    //       line-height: 0.3rem;
    //       color: #666;
    //     }
    //   }
    // }
  }
}
.highlights {
  text-align: center;
  h6 {
    font-size: 0.28rem;
    padding: 0 0 0.74rem;
  }
  .highlights_c {
    width: 11.38rem;
    height: 3.4rem;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 0rem 0.89rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background-image: url(../../assets/images/highlights_bj.png);
    background-size: 100% 100%;
    div {
      width: 4rem;
      text-align: left;
      padding: 0.34rem 0.2rem 0;
      h4 {
        font-size: 0.22rem;
        padding-bottom: 0.14rem;
        span {
          display: inline-block;
          width: 0.32rem;
          line-height: 0.32rem;
          background-color: #ffc53d;
          color: #fff;
          border-radius: 0.05rem;
          text-align: center;
        }
      }
      p {
        color: #333;
        font-size: 0.18rem;
        line-height: 0.27rem;
      }
    }
  }
}
.industry {
  margin-top: 1.2rem;
  height: 3.31rem;
  background-image: url(../../assets/images/ap_bj.png);
  background-size: 100% 100%;
  text-align: center;
  h6 {
    color: #fff;
    font-size: 0.22rem;
    padding-top: 0.96rem;
  }
  button {
    color: #fff;
    background-color: #ffc53d;
    width: 1.48rem;
    height: 0.64rem;
    border: 0;
    border-radius: 0.05rem;
  }
}
</style>
